<template src="./transactionreports.html"></template>
<style scoped src ="./transactionreports.css"></style>

<script>
import Loading from 'vue-loading-overlay'
import { required } from 'vuelidate/lib/validators'
import applicationConstants from '@/constants/AppConstants.vue'
import reportServices from '@/services/ReportService.vue'
import modal from '@/components/modal/notification/modal'
import utils from '@/utils/utils'
import moment from 'moment'
import jsonToExcel from 'vue-json-excel'
import pagination from '@/components/vtable/vtpagination.vue'

export default  {
    components : {
        Loading, modal, jsonToExcel, pagination
    },
    data () {
        return {
            email : '',
            fromDate : null,
            toDate : null,
            transactionTable : [],
            loaderVisible : false,
            errorMessages: applicationConstants.formsMessages,
            columns : ['TxnTime','FundingTxnDetail_RefNo','FundingTxnDetail_TotalAmountt','FundingType','MoneyTransferTxnDetail_RefNo','MoneyTransferTxn_Fee', 'PromoTxnDetail_TotalAmt', 'C_Name', 'ClientAppId', 'RemitStatus'],
            options : {
                headings : {
                    TxnTime : 'Transaction Time(UTC)',
                    FundingTxnDetail_RefNo : 'Funding RefNo',
                    FundingTxnDetail_TotalAmountt : 'Funding Amount',
                    FundingType : 'Funding Type',
                    MoneyTransferTxnDetail_RefNo : 'MoneyTransfer RefNo',
                    MoneyTransferTxn_Fee : 'MoneyTransfer Fee',
                    PromoTxnDetail_TotalAmt : 'Promo Amount',
                    C_Name : 'Customer Name',
                    ClientAppId : 'ClientAppId',
                    RemitStatus : 'Remit Status',
                }
            },
            alertObject: {
                errorCode: null,
                errorDescription: null,
                errorDetails: []
            },
            csvFields: {
                'TxnTime': 'TxnTime',
                'ParentId': 'ParentId',
                'ParentRefNo': 'ParentRefNo',
                'FundingType': 'FundingType',
                'FundingTypeDetails': 'FundingTypeDetails',
                'FundingTxn_TotalAmt': 'FundingTxn_TotalAmt',
                'PromoTxn_TotalAmt': 'PromoTxn_TotalAmt',
                'Target Currency': 'Target Currency',
                'Source Currency': 'Source Currency',
                'MoneyTransferTxn_TotalAmt': 'MoneyTransferTxn_TotalAmt',
                'MoneyTransferTxn_Fee': 'MoneyTransferTxn_Fee',
                'MoneyTransferTxn_Cost': 'MoneyTransferTxn_Cost',
                'ExchangeRate': 'ExchangeRate',
                'RemittanceGateway': 'RemittanceGateway',
                'MathAddsUp': 'MathAddsUp',
                'ClientAppId': 'ClientAppId',
                'FundingTxnDetails': 'FundingTxnDetails',
                'FundingTxnDetail_Time': 'FundingTxnDetail_Time',
                'FundingTxnDetail_Id': 'FundingTxnDetail_Id',
                'FundingTxnDetail_RefNo': 'FundingTxnDetail_RefNo',
                'FundingTxnDetail_TotalAmountt': 'FundingTxnDetail_TotalAmountt',
                'FundingTxnDetail_Fee': 'FundingTxnDetail_Fee',
                'FundingTxnDetail_Cost': 'FundingTxnDetail_Cost',
                'PromoTxnDetails': 'PromoTxnDetails',
                'PromoTxnDetail_Time': 'PromoTxnDetail_Time',
                'PromoTxnDetail_Id': 'PromoTxnDetail_Id',
                'PromoTxnDetail_RefNo': 'PromoTxnDetail_RefNo',
                'PromoTxnDetail_TotalAmt': 'PromoTxnDetail_TotalAmt',
                'MoneyTransferTxnDetails': 'MoneyTransferTxnDetails',
                'MoneyTransferTxnDetail_Time': 'MoneyTransferTxnDetail_Time',
                'MoneyTransferTxnDetail_Id': 'MoneyTransferTxnDetail_Id',
                'MoneyTransferTxnDetail_RefNo': 'MoneyTransferTxnDetail_RefNo',
                'MoneyTransferTxnDetail_TotalAmt': 'MoneyTransferTxnDetail_TotalAmt',
                'MoneyTransferTxnDetail_Fee': 'MoneyTransferTxnDetail_Fee',
                'MoneyTransferTxnDetail_Cost': 'MoneyTransferTxnDetail_Cost',
                'CustomerDetails': 'CustomerDetails',
                'C_Name': 'C_Name',
                'C_Email': 'C_Email',
                'C_Mobile': 'C_Mobile',
                'C_Address': 'C_Address',
                'C_City': 'C_City',
                'C_Province': 'C_Province',
                'C_SignUpDate': 'C_SignUpDate',
                'C_IsDemoAccount': 'C_IsDemoAccount',
                'C_IsMarketer': 'C_IsMarketer',
                'C_RiskScore': 'C_RiskScore',
                'C_EmailConfirmed': 'C_EmailConfirmed',
                'C_EIDKey': 'C_EIDKey',
                'TxnC_EIDRunDateTime': 'C_EIDRunDate',
                'RecipientDetails': 'RecipientDetails',
                'R_Name': 'R_Name',
                'R_Mobile': 'R_Mobile',
                'R_Address': 'R_Address',
                'R_Country': 'R_Country',
            },
            enableLazyLoading: applicationConstants.enableLazyLoading
        }
    },
    methods :{
        async getTransactionData() {
            try {
                let fDate = null, tDate = null
                this.$v.$touch()
                if(this.$v.$invalid){
                    return
                }else if(this.fromDate && this.toDate){
                    fDate = utils.methods.LocalToUTC(moment(moment(this.fromDate)._d).format('MM-DD-YYYY hh:mm:ss'))
                    tDate = utils.methods.LocalToUTC(moment(moment(this.toDate)._d).format('MM-DD-YYYY hh:mm:ss'))
                    if(this.fromDate > this.toDate){
                        this.showAlert(null, 'FromDate should be less than ToDate')
                        return
                    }
                }
                this.$v.$reset()
                this.loaderVisible = true;
                    reportServices.methods.GetFullTransaction({
                        Customer: this.email ? this.email : null,
                        TransactionDateFrom: fDate ? fDate : null,
                        TransactionDateTo: tDate ? tDate : null
                    })
                    .then(response =>{
                        this.transactionTable = response.csGetTransactionReportResponse?.TransactionReport?.TransactionReportData
                        this.transactionTable = this.removeUnderscoreText(this.transactionTable ? (Array.isArray(this.transactionTable) ? this.transactionTable : [this.transactionTable]) : [])
                        if (this.enableLazyLoading) {
                            this.$refs.paginationRef.selectedPage = 1
                        } else {
                            this.$refs.vueClientTable.setPage(1)
                        }
                        this.loaderVisible = false
                    })
                    .catch(error =>{
                        this.transactionTable = []
                        this.loaderVisible = false
                        this.alertObject = error
                        this.$bvModal.show(this.$refs.childModal.id)
                    })
            } catch (error) {
                //
            }
        },
        removeUnderscoreText(data) {
           if(data) {
                const fullTransactionReport = (report) => {
                    Object.values(report).map((el, key) => {
                        const Objectkey = Object.keys(report)[key]
                        report[Objectkey] = el._text
                        if(Objectkey === 'TxnTime') {
                            report[Objectkey] = report[Objectkey].includes('AM') ? report[Objectkey].replace('AM', " AM") : report[Objectkey].replace('PM', " PM")
                        }
                    })
                }
                data.map(fullTransactionReport)
                data.sort(function (a,b) {
                    return new Date(b.TxnTime) - new Date(a.TxnTime)
                })
           }else {
               data = []
           }
           return data
        },
        clearAllFields() {
            this.email = '',
            this.fromDate = null
            this.toDate = null
            this.$v.$reset()
        },
        showAlert(code, description) {
            this.alertObject.errorCode = code
            this.alertObject.errorDescription = description
            this.alertObject.errorDetails = []
            this.$bvModal.show(this.$refs.childModal.id)
        },
        exportTableData() {
           return this.transactionTable.length > 0 ? this.transactionTable : []
        },
    },
    computed : {
        today: () => new Date(),
        transactionTableComputed () {
            let report = []
            if(this.transactionTable && this.transactionTable.length > 0) {
                report = JSON.parse(JSON.stringify(this.transactionTable))
                if(this.enableLazyLoading) {
                    let selectedPage = this.$refs.paginationRef?.selectedPage
                    report = report.slice((selectedPage - 1) * 10, selectedPage * 10)
                }
                const updateTotalRecords = () => {
                    if(this.$refs.paginationRef) {
                        this.$refs.paginationRef.totalRecords = this.transactionTable?.length ?? 0
                    }
                }
                if (this.enableLazyLoading) {
                    updateTotalRecords();
                }
            }
            return report
        }
    },
    validations: {
        fromDate: { required },
        toDate: { required }
    }
}
</script>